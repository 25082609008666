import {Controller} from 'react-hook-form';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';

import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useEmployeeForm';

import styles from './EmployeeForm.module.scss';
import roles from 'constants/roles';
import {Fragment, useCallback, useState} from 'react';
import useDeleteEmployee from './useDeleteEmployee';
import {useHistory} from 'react-router-dom';
import EyeIcon from './icons/eye.svg';
import EyeDisabledIcon from './icons/eye-disable.svg';

function EmployeeForm() {
  const {
    role,
    employeeId,
    options,
    submitting,
    errors,
    control,
    register,
    handleSubmit,
  } = useEmployeeForm();
  const history = useHistory();
  const {deleteEmployee, isDeleting} = useDeleteEmployee();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);
      history.replace('/settings/employees');
    },
    [deleteEmployee, history]
  );

  return (
    <form onSubmit={handleSubmit}>
      <PageHeader>
        <Typography variant="h2">
          {employeeId ? 'Edit Employee' : 'New Employee'}
        </Typography>
        <FormActionGroup>
          {!!employeeId && (
            <Button
              onClick={() => handleDeleteEmployee(+employeeId)}
              color="danger"
              loading={isDeleting}
            >
              Delete
            </Button>
          )}
          <Button to="/settings/employees" component="a" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Surname"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="Phone number"
              {...register('phone')}
              error={errors.phone?.message}
            />
          </FormGroup>

          {/* <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register('address_line_1')}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register('address_line_2')}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register('city')}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register('zipcode')}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              render={({field, fieldState}) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup> */}

          <Typography className={styles.header} variant="h3">
            Account
          </Typography>

          <FormGroup columns={3}>
            <TextField
              label="Username"
              placeholder="Username"
              {...register(
                'username',
                !employeeId
                  ? {
                      required: 'Required',
                    }
                  : {}
              )}
              error={errors.username?.message}
            />
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register(
                  'password',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register(
                  'password_confirmation',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>

          <FormGroup columns={1}>
            <Controller
              name="role"
              control={control}
              rules={{required: 'Required'}}
              render={({field}) => (
                <SelectField
                  label="Employee Type"
                  placeholder="Employee Type"
                  options={roles}
                  {...field}
                />
              )}
            />
          </FormGroup>

          <Fragment>
            <Typography className={styles.header} variant="h3">
              {role}
            </Typography>
            <FormGroup columns={1}>
              <Controller
                name="team"
                control={control}
                render={({field}) => (
                  <SelectField
                    label="Assigned Team"
                    placeholder="No assigned team"
                    options={options}
                    {...field}
                  />
                )}
              />
              {/* <Controller
                name="field_memebers"
                control={control}
                render={({field}) => (
                  <SelectField
                    label="Assigned Field Members"
                    placeholder="No assigned field member"
                    options={options}
                    {...field}
                  />
                )}
              /> */}
              {/* <TextField
                label="Travel Method"
                placeholder="Travel Method"
                {...register(
                  'travel_method',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.travel_method?.message}
              /> */}
            </FormGroup>
          </Fragment>
        </CardContent>
      </ListWrapper>
    </form>
  );
}

export default EmployeeForm;
