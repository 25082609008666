import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/SelectField';

import api from 'api';

import {Driver, ListResponse, Vehicle} from 'types';
import {getFullName} from 'helpers/user';
import {get} from 'lodash';

interface Option {
  label: string;
  value: number;
}

interface FormValue {
  name: string;
  model: string;
  email: string;
  license_plate_number: string;
  driver?: Option;
  travel_method?: {
    value: 'car' | 'bike';
    label: string;
  };
}

async function getDrivers() {
  const {data} = await api.get<ListResponse<Driver>>('/drivers/');

  return data;
}

async function getVehicleById({queryKey}: QueryFunctionContext) {
  const [, vehicleId] = queryKey;

  if (!vehicleId) return undefined;

  const {data} = await api.get<Vehicle>(`/teams/${vehicleId}`);

  return data;
}

function VehicleForm() {
  const {vehicleId} = useParams<{vehicleId?: string}>();
  const {push, replace} = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
  } = useForm<FormValue>();

  const {data: drivers} = useQuery('drivers', getDrivers);
  const {data: vehicle} = useQuery(['vehicle', vehicleId], getVehicleById);

  const options = useMemo(
    function () {
      if (!drivers) return [];

      return (
        drivers.results
          // .filter((driver) => !driver.is_assigned)
          .map((d) => ({
            value: d.id,
            label: d.full_name,
          }))
      );
    },
    [drivers]
  );

  useEffect(
    function () {
      if (!vehicle) return;

      reset({
        ...vehicle,
        driver: vehicle.driver
          ? {
              value: vehicle.driver.id,
              label: getFullName(vehicle.driver),
            }
          : undefined,
        travel_method: vehicle.travel_method
          ? {
              value: vehicle.travel_method,
              label: vehicle.travel_method === 'car' ? 'Car' : 'Bike',
            }
          : {
              value: 'car',
              label: 'Car',
            },
      });
    },
    [vehicle, reset]
  );

  async function submit(formValue: FormValue) {
    setSubmitting(true);

    const data = {
      model: formValue.model,
      name: formValue.name,
      driver: formValue.driver?.value,
      travel_method: get(formValue, 'travel_method.value', 'car'),
    };

    try {
      if (vehicleId) {
        await api.put(`/teams/${vehicleId}/`, data);
      } else {
        await api.post('/teams/', data);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }

    push('/settings/vehicles');
  }

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/teams/${id}/`);
        replace('/settings/vehicles');
      } catch (error) {}
    },
    [replace]
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <PageHeader>
        <Typography variant="h2">
          {vehicleId ? 'Edit Team' : 'New Team'}
        </Typography>
        <FormActionGroup>
          {!!vehicleId && (
            <Button
              onClick={() => handleDeleteEmployee(+vehicleId)}
              color="danger"
            >
              Delete
            </Button>
          )}
          <Button component="a" to="/settings/vehicles" color="gray">
            Cancel
          </Button>
          <Button type="submit" color="green" loading={submitting}>
            Save
          </Button>
        </FormActionGroup>
      </PageHeader>
      <FormGroup columns={2}>
        <TextField
          label="Team name"
          placeholder="Name"
          {...register('name', {required: 'Required'})}
          error={errors.name?.message}
        />
        <TextField
          label="Vehicle name"
          placeholder="Vehicle name"
          {...register('model', {required: 'Required'})}
          error={errors.model?.message}
        />
      </FormGroup>

      <FormGroup columns={1}>
        <Controller
          name="driver"
          control={control}
          render={({field, fieldState}) => (
            <SelectField
              label="Assigned field member"
              placeholder="Select field member"
              options={options}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </FormGroup>
      <FormGroup columns={1}>
        <Controller
          name="travel_method"
          control={control}
          render={({field, fieldState}) => (
            <SelectField
              label="Travel method"
              placeholder="Select travel method"
              options={[
                {value: 'car', label: 'Car'},
                {value: 'bike', label: 'Bike'},
              ]}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </FormGroup>
    </form>
  );
}

export default VehicleForm;
