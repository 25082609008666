import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';

import Typography from 'components/Typography';
import Select from 'components/Select';
// import Input from 'components/Input';

import {SelectOption, Vehicle} from 'types';

import truck from '../../icons/truck.svg';
import vehicle from '../../icons/vehicle.svg';
import location from '../../icons/location.svg';

import styles from '../../DeliveryPlanner.module.scss';
import localStyles from './AssignedHeader.module.scss';
import {SingleValue} from 'react-select';
import {get} from 'lodash';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import DateTimePicker from 'components/DateTimePicker';
import {parseJSON} from 'date-fns';
import TextField from 'components/TextField';

interface Props {
  currentVehicle?: Vehicle;
  vehicles?: Vehicle[];
  startLocation: {
    name: string;
    lat: number;
    lng: number;
  };
  endLocation: {
    name: string;
    lat: number;
    lng: number;
  };
  onChange?: (
    startLocation: string,
    endLocation: string,
    currentVehicle?: Vehicle
  ) => void;
  onChangeStartTime?: (time: any) => void;
  start_time?: string | null;
  routes?: number;
  roundedTime: Date;
  driver: string;
}

function AssignedHeader({
  currentVehicle,
  vehicles,
  startLocation,
  endLocation,
  start_time,
  routes,
  roundedTime,
  driver,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>();
  const [endpoints, setEndpoints] = useState({
    startLocation: {
      name: '',
      lat: 0,
      lng: 9,
    },
    endLocation: {
      name: '',
      lat: 0,
      lng: 9,
    },
  });

  const options = useMemo(
    function () {
      return vehicles?.map((v) => ({
        label: (
          <div
            aria-disabled={!get(v, 'is_available', true)}
            className={localStyles.option}
          >
            <img alt="truck" src={vehicle} />
            {v.name}
            {!get(v, 'is_available', true) && (
              <div className={localStyles.driverStatusWrap}>
                <p className={localStyles.driverStatus}>busy</p>
              </div>
            )}
          </div>
        ),
        value: v.id,
      }));
    },
    [vehicles]
  );

  function onChangeVehicle(newValue: SingleValue<SelectOption>) {
    setSelectedVehicleId(Number(newValue?.value));
  }

  useEffect(
    function () {
      setSelectedVehicleId(currentVehicle?.id);
      setEndpoints({
        startLocation,
        endLocation,
      });
    },
    [currentVehicle, startLocation, endLocation]
  );

  return (
    <>
      <div className={classNames(styles.panel__header)}>
        <div className={styles.panel__vehicle_title}>
          {!currentVehicle?.name && (
            <p className={styles.panel__vehicle}>
              <img alt="truck icon" src={truck} />
              {currentVehicle?.name || 'not assigned'}
            </p>
          )}
          {!!currentVehicle && (
            <p className={styles.panel__endpoints}>
              <Typography component="span" variant="p">
                Router: {routes} Jobs
              </Typography>
            </p>
          )}
        </div>
        <span
          className={styles.panel__change_vehicle}
          onClick={() => setExpanded((prev) => !prev)}
        >
          Assign
        </span>
      </div>
      <div
        className={classNames(localStyles.form, {
          [localStyles['--expanded']]: expanded,
        })}
      >
        {expanded && (
          <div className={localStyles.form__content}>
            <div className={localStyles.label}>Vehicle:</div>
            <Select
              size="sm"
              isDisabled
              options={options}
              className={localStyles.select}
              value={options?.find((o) => o.value === selectedVehicleId)}
              onChange={onChangeVehicle}
            />
            <div className={localStyles.label}>Driver:</div>
            <TextField defaultValue={driver} disabled label="" />
            <div className={localStyles.label}>Start loc:</div>
            <div className={localStyles.root}>
              <div className={localStyles.icon_wrapper}>
                <img alt="location" src={location} />
              </div>
              <GooglePlaceAutoComplete
                style={{paddingLeft: 25}}
                disabled
                defaultValue={`${endpoints.startLocation.name}`}
                placeholder="Search address"
                onSelectPlace={(placeDetail) => {
                  setEndpoints((pr) => ({
                    ...pr,
                    startLocation: {
                      name: placeDetail.addressLine1,
                      lat: placeDetail.lat,
                      lng: placeDetail.lon,
                    },
                  }));
                }}
                options={{
                  types: ['address'],
                }}
              />
            </div>

            <div className={localStyles.label}>End loc:</div>
            <div className={localStyles.root}>
              <div className={localStyles.icon_wrapper}>
                <img alt="location" src={location} />
              </div>
              <GooglePlaceAutoComplete
                disabled
                style={{paddingLeft: 25}}
                placeholder="Search address"
                defaultValue={`${endpoints.endLocation.name}`}
                onSelectPlace={(placeDetail) => {
                  setEndpoints((pr) => ({
                    ...pr,
                    endLocation: {
                      name: placeDetail.addressLine1,
                      lat: placeDetail.lat,
                      lng: placeDetail.lon,
                    },
                  }));
                }}
                options={{
                  types: ['address'],
                }}
              />
            </div>
            <div className={localStyles.label}>Time:</div>
            <div className={localStyles.calendarRoot}>
              <DateTimePicker
                disabled={true}
                minTime={roundedTime}
                maxTime={new Date(new Date().setHours(23, 59, 59))}
                minDate={roundedTime}
                value={start_time ? parseJSON(start_time) : undefined}
                onChange={() => {}}
              />
            </div>
            <div className={localStyles.actions}></div>
          </div>
        )}
      </div>
    </>
  );
}

export default AssignedHeader;
